.app-menu{
    --background: #f3f3f3;
    &.mobile ion-list{
        margin: 15px 0px;
    }

    &.iphone ion-list {
        margin: 40px 0px 15px 0px;
    }

    ion-menu.md {
        ion-content {
            --padding-start: 8px;
            --padding-end: 8px;
            --padding-top: 20px;
            --padding-bottom: 20px;
        }

        ion-note {
            margin-bottom: 30px;
        }

        ion-list-header,
        ion-note {
            padding-left: 10px;
        }

        ion-list#inbox-list {
            border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
            background-color: red;
        }

        ion-list#inbox-list ion-list-header {
            font-size: 22px;
            font-weight: 600;
            min-height: 20px;
        }

        ion-list#labels-list ion-list-header {
            font-size: 16px;
            margin-bottom: 18px;
            color: #757575;
            min-height: 26px;
        }

        ion-item {
            --padding-start: 10px;
            --padding-end: 10px;
            border-radius: 4px;
        }

        ion-item.selected {
            --background: rgba(var(--ion-color-primary-rgb), 0.14);
        }

        ion-item ion-icon {
            color: var(--ion-color-primary);
        }

        ion-item.selected ion-icon {
            color: var(--ion-color-medium);
        }

        ion-item ion-label {
            font-weight: 500;
        }
    }

    ion-menu.ios {
        ion-content {
            --padding-bottom: 20px;
        }

        ion-note {
            line-height: 24px;
            margin-bottom: 20px;
        }

        ion-item {
            --padding-start: 16px;
            --padding-end: 16px;
            --min-height: 40px;
        }

        ion-item ion-icon {
            font-size: 24px;
            color: var(--ion-color-primary);
        }

        ion-item .selected ion-icon {
            color: var(--ion-color-medium);
        }

        ion-list#labels-list ion-list-header {
            margin-bottom: 8px;
        }

        ion-list-header,
        ion-note {
            padding-left: 16px;
            padding-right: 16px;
        }

        ion-note {
            margin-bottom: 8px;
        }
    }

    ion-note {
        display: inline-block;
        font-size: 16px;
        color: var(--ion-color-medium-shade);
    }

    ion-item.selected {
        --color: var(--ion-color-primary);
    }

    ion-menu-toggle ion-item {
        border-bottom: 1px solid var(--ion-color-light-shade);
        color: var(--ion-color-secondary);
        font-size: 14px;
        font-weight: 500;
        .menu-title-extra {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                margin: 0px;
                padding: 3px 8px;
                font-size: 11px;
                color: rgb(130, 148, 164);
                background-color: rgb(245, 247, 248);
                border-radius: 10.5px;
            }
        }
        ion-icon {
            margin-right: 10px;
            color: var(--ion-color-primary);
            &:hover {
                color: var(--ion-color-medium);
            }
        }
    }
}