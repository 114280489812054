.document-scanner-content{
    .scanned-img-item {
        position: relative;
        margin-bottom: 20px;
        img{
            width: 100%;
        }
        .img-toolbar{ 
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            display: flex;
            justify-content: space-between;
            ion-icon{
                width: 22px;
                height: 22px;
                background-color: white;
                border-radius: 50%;
                padding: 8px;
                color: var(--ion-color-primary);
                margin-right: 10px;
                border: 1px solid var(--ion-input-border-color);
            }
            .trash-icon{
                color: red;
                margin-right: unset;
            }
        }
    }
}