.estate-list-item {
    &:nth-child(odd) {
        background-color: #f4fafb;
    }
    &:not(.mobile) {
        width: 420px;
    }
    display: flex;
    padding: 16px;
    cursor: pointer;
    .estate-picture-block {
        flex-shrink: 0;
        position: relative;
        .estate-picture {
            width: 96px;
            height: 96px;
            border-radius: 10px;
            margin-right: 10px;
            object-fit: cover;
        }
        .energy-class {
            position: absolute;
            top: 6px;
            right: 6px;
            height: 20px;
        }
    }

    .info {
        display: grid;
        width: 100%;
        padding: 9px 0px;
        .title {
            color: var(--ion-color-secondary);
            font-size: 16px;
            font-weight: 500;
        }
        .price-status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .price {
                color: var(--ion-color-primary);
                font-weight: 700;
                font-size: 18px;
                flex-shrink: 1;
            }
            .status-label{
                flex-shrink: 0;
                max-width: 50%;
            }
        }
        .details {
            display: flex;
            flex-wrap: wrap;
            & > * {
                margin-right: 2px;
            }
        }
    }
}
