.gmap-property-name-tooltip{
    font-weight: 500;
    color: var(--ion-color-secondary);
    font-size: 14px;
}
.gmap-property-name-tooltiplink{
    cursor: pointer;
    font-weight: 500;
    color: var(--ion-color-primary);
    text-decoration: underline;
    font-size: 14px;
}
.gm-ui-hover-effect {
    outline: none;
}