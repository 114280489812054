.action-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    &:last-of-type {
        padding-bottom: 19px;
    }
    flex-wrap: wrap;
    .action {
        display: flex;
        flex: 1;
        align-items: center;
        width: calc(100% - 200px);
        .action-icon {
            margin-right: 16px;
        }
        .action-data {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #626262;
            flex: 1;
            width: calc(100% - 35px);
            .action-name {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 4px;
                color: #3e6fc3;
                &.mobile {
                    display: flex;
                    justify-content: space-between;
                    .check-icon {
                        margin-right: 5px;
                        margin-left: 8px;
                        min-width: 24px;
                        flex-shrink: 0;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }

    .attachment-web {
        display: flex;
        justify-content: space-between;
        margin-right: 5px;
    }
    .attachment-mobile {
        padding: 12px 0;
        width: 100%;
        margin-top: 12px;
        justify-content: space-between;
        display: flex;
        border-top: 1px solid #86949f57;
        border-bottom: 1px solid #86949f57;
    }
    .attachment-name {
        font-weight: 400;
        font-size: 14px;
        color: #475b6c;
        align-self: center;
        margin-right: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .delete-attachment {
        cursor: pointer;
        vertical-align: middle;
        &.disabled {
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
        }
    }
    .button {
        display: flex;
        align-items: center;
        height: 40px;
        max-width: 200px;
        .complete-button {
            --background: white;
        }

        .see-button {
            --background: #3e6fc3;
            color: white;
        }
    }
    .button::part(native) {
        padding: 0 24px;
    }
    &:nth-of-type(even) {
        background-color: #edf6f999;
    }

    &.mobile {
        padding: 16px;
        .action {
            .action-icon {
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                min-width: 38px;
                width: 38px;
                height: 38px;

                > svg {
                    width: 20px;
                    height: 20px;
                    display: flex;
                }
            }
        }
        .button {
            max-width: unset;
        }
    }
}
