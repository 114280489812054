.questionnaire-page {
    > div {
        padding: 16px 16px 16px 24px;
    }
    ion-header {
        font-size: 24px;
        color: var(--ion-color-primary);
        font-weight: 700;
        margin-bottom: 10px;
        padding: 16px 0;
        ion-button {
            position: absolute;
            right: 0;
            top: 5px;
            padding: 0;
            margin: 0;
            height: auto;
            color: var(--ion-color-medium-text);
        }
        ion-title {
            font-size: 20px;
        }
    }
    ion-card-header {
        padding: 0 0 10px 0;
        ion-card-title {
            font-size: 17px;
        }
    }
    .question-list {
        flex: 1;
        .question-item {
            padding: 0 0 10px 10px;
        }
        .question-number {
            padding-right: 10px;
        }
        .question-name ion-item {
            --padding-start: 0;
        }
    }

    ion-card-content {
        color: var(--ion-color-secondary);
        font-size: 16px;
        .item ion-label,
        .item .question-number {
            color: var(--ion-color-dark-tint);
            font-size: 16px;
        }
    }
    ion-footer {
        text-align: right;
        .button-outline {
            margin-right: 20px;
        }
    }

    ion-item:not(.item-label) .sc-ion-input-ios-h {
        color: var(--ion-color-dark-tint);
    }
}

@media screen and (min-width: 993px) {
    .questionnaire-page {
        --width: 900px;
        --height: 80vh;
        .answer-list {
            ion-radio-group {
                display: flex;
                padding-left: 20px;
                &.user-item {
                    padding-left: 30px;
                }
                ion-item {
                    border-radius: 10px;
                    ion-radio {
                        margin-inline: 0px 10px;
                    }
                    &.item-radio-checked::part(native) {
                        background-color: var(--ion-color-light);
                    }
                }
            }
            ion-item:not(.item-label) .sc-ion-input-ios-h {
                --padding-start: 10px;
            }
            ion-input {
                margin: 10px 0;
            }
        }
        .question-list {
            ion-item:not(.item-label) .sc-ion-input-ios-h {
                --padding-start: 10px;
            }
            /*.question-item:nth-of-type(even) {
                background-color: #edf6f999;
            }*/
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
    .questionnaire-page {
        --width: 90vw;
        --height: 80vh;
        .answer-list {
            ion-radio-group {
                display: flex;
                ion-item {
                    --padding-start: 0;
                    ion-radio {
                        margin-inline: 0px 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .questionnaire-page {
        ion-header {
            ion-button::part(native) {
                padding-inline-end: 0;
            }
        }
        ion-footer {
            ion-button {
                width: 100%;
            }
        }
        ion-card-header {
            ion-card-title {
                font-size: 16px;
            }
        }
        .add-new-item {
            --padding-start: 0;
        }
        .question-list {
            .question-item {
                padding-bottom: 20px;
            }
        }
    }
}
