@media (pointer: fine), (pointer: none) {
    ion-item {
        --show-full-highlight: 0;
        --show-inset-highlight: 0;
        --border-width: 0;
        ion-label {
            margin-bottom: 9px;
            font-size: 14px;
            color: var(--ion-color-secondary);
        }
        ion-input {
            border: 1px solid var(--ion-input-border-color);
            border-radius: 10px;
            margin-bottom: 2px;
            &:hover {
                border: 1px solid var(--ion-color-primary);
            }
            &.has-focus {
                border: 1px solid var(--ion-color-primary);
            }
            input {
                padding: 5px 10px;
                border-radius: 10px;
                font-size: 14px;
                line-height: 1.4;
                &::placeholder {
                    font-size: 14px;
                }
            }
        }
    }
}
