.estate-main-wrapper {
    .estate-header {
        position: relative;
        .estate-img {
            width: 100%;
            height: -webkit-fill-available;
            object-fit: cover;
        }
        .nav-button-wrapper {
            position: absolute;
            top: 20px;
            width: 100%;
        }
    }
    .estate-header-card-wrapper {
        width: 100%;
        .agent-card {
            width: 100%;
            .profile-data {
                display: flex;
                align-items: center;
                .agent-info {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    min-width: 0;
                    margin-right: 10px;
                    //svg for fallback
                    .agent-img, svg {
                        width: 80px;
                        height: 80px;
                        object-fit: contain;
                        border-radius: 50%;
                        margin-right: 20px;
                        box-shadow: 0px 0px 3px -1px gray;
                    }
                    .agent-data {
                        font-weight: 700;
                        line-height: 32px;
                        color: var(--ion-color-secondary);
                        min-width: 0;
                        flex: 1;
                        .agent-contact-info-line{
                            display: flex;
                            align-items: center;
                            .agent-name{
                                margin-right: 10px;
                                flex: 1;
                            }
                            .agent-office-label{
                                font-weight: 300;
                                margin-right: 5px;
                            }
                            .agent-office-name{
                                margin-right: 5px;
                                flex: 1;
                            }
                            .header-info-item{
                                display: flex;
                                align-items: center;
                                color: var(--ion-color-secondary);
                                padding: 5px 0px;
                                margin-right: 10px;
                                a{
                                    font-size: 15px;
                                    display: flex;
                                    align-items: center;
                                    line-height: 1.5;
                                    color: var(--ion-color-primary);
                                    text-decoration: none;
                                    font-weight: 500;
                                }
                                ion-icon{
                                    font-size: 20px;
                                    margin-right: 5px;
                                    color: var(--ion-color-primary);
                                    &.tel{
                                        transform: rotate(20deg);
                                        margin-right: 1px;
                                        font-size: 18px;
                                    }
                                }
                                .office-name{
                                    line-height: 1.5;
                                    font-weight: 500;
                                    font-size: 15px;
                                    color: var(--ion-color-secondary);
                                }
                                &:last-child{
                                    margin-right: unset;
                                }
                            }
                        }
                    }
                }
            }
        }

        .tab-details {
            margin-top: 15px;
            ion-text {
                display: flex;
            }

            span {
                color: var(--ion-color-secondary);
                font-weight: 500;
                line-height: 24px;
            }

            ion-icon {
                color: var(--ion-color-primary);
                min-width: 24px;
                height: 24px;
                margin-right: 10px;
            }
        }
    }
    .no-margin {
        margin: unset;
    }

    .tab-content {
        padding: 0px 16px;
        margin-top: 75px;
    }

    &.mobile {
        .estate-header {
            height: 170px;
            .nav-button-wrapper {
                padding: 0px 16px;
            }
        }
        .estate-header-card-wrapper {
            margin-top: -200px;
            top: 4px;
            padding: 16px 16px 0px;
            .agent-card {
                margin-top: 135px;
                ion-card-content {
                    padding: 15px;
                }
                .agent-info {
                    margin-right: 0px;
                    min-width: 0;
                    .agent-img {
                        width: 56px;
                        height: 56px;
                        margin-right: 15px;
                    }
                    .agent-data {
                        font-size: 18px;
                        line-height: 26px;
                        .agent-name{
                            font-size: 20px;
                        }
                        .agent-office-label{
                            font-size: 17px;
                        }
                        .agent-office-name{
                            font-size: 17px;
                        }
                        ion-icon{
                            &:not(.tel){
                                font-size: 23px !important;
                            }
                            &.tel{
                                font-size: 21px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    &:not(.mobile) {
        .estate-header {
            height: 280px;
            .nav-button-wrapper {
                padding: 0px 10%;
            }
        }
        .estate-header-card-wrapper{
            margin-top: -250px;
            padding: 0px 10%;
            top: 40px;
            .agent-card {
                margin-top: 150px;
                ion-card-content {
                    padding: 25px;
                    .profile-data {
                        .agent-name{
                            font-size: 24px;
                        }
                        .agent-office-label{
                            font-size: 20px;
                        }
                        .agent-office-name{
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}
