.android-file-uploader{
    .file-upload-list{
        padding: 25px 20px;
        .file-upload-option{
            display: flex;
            align-items: center;
            padding: 10px;
            justify-content: space-between;
            color: #000;
            .file-upload-label{
                font-size: 20px;
                font-weight: 400;
            }
            ion-icon{
                font-size: 27px;
                color: var(--ion-color-primary);
            }
            &:not(:last-child){
                border-bottom: 1px solid;
                border-color: var(--ion-input-border-color);
            }
        }
    }
}