.smart-banner{
    position: relative;
    width: 100vw;
    height: 80px;
    padding: 10px;
    background-color: #f2f2f2;
    z-index: 1;
    box-shadow: 0 1px 1px rgba(0,0,0,.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close{
        position: absolute;
        left: 5px;
        color: black;
    }
    img{
        max-height: 100%;
        margin-left: 15px;
    }
    .descriptions{
        .app-name{
            font-size: 16px;
            font-weight: 500;
            color: black;
            margin-bottom: 1px;
        }
        .company-name{
            font-size: 14px;
            color: var(--ion-color-secondary);
            margin-bottom: 2px;
        }
        .text{
            font-size: 13px;
            color: var(--ion-color-secondary);
        }
    }
    .action{
        color: var(--ion-color-primary);
    }
}