.estate-preview-modal {
    .swiper {
        height: 300px;
    }
    &:not(.mobile) {
        --width: 500px;
        --height: auto;
        --max-height: 80vh;
        .ion-delegate-host{
            max-height: 80vh;
        }
    }
    &.isMobile {
        .estate-preview {
            .details {
                padding: unset;
                ion-col {
                    padding-top: unset;
                    padding-bottom: unset;
                }
            }
            .estate-preview-content {
                padding: 15px;
            }
        }
    }
    .estate-preview {
        width: inherit;
        position: relative;
        overflow-y: auto;
        .estate-preview-content {
            padding: 30px;
            .estate-name {
                color: var(--ion-color-secondary);
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 10px;
            }
            .price-status {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                .price {
                    font-size: 24px;
                    font-weight: 700;
                    color: var(--ion-color-primary);
                }
            }
            .description {
                color: #86949f;
                font-size: 15px;
                font-weight: 400;
            }
            .details {
                padding: unset;
                ion-col {
                    padding: 0px 0px 5px;
                }
            }
        }
        .close-btn {
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 20px;
            border-radius: 50%;
            display: flex;
            padding: 7px;
            color: var(--ion-color-secondary);
            background-color: #fff;
            font-size: 18px;
            z-index: 1;
        }
        &.ios-device{
            .close-btn {
                right: 20px;
                top: 20px;
            }
        }
    }
    .powered-by-wrapper {
        opacity: 0.2;
        filter: grayscale(1);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
