.search-counts {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .count-info {
        display: flex;
        align-items: center;
    }
    .value {
        margin-right: 15px;
        color: var(--ion-color-primary);
        background: #f7f7f7;
        font-weight: 700;
        font-size: 25px;
        padding: 10px 18px;
        border-radius: 10px;
    }
    ion-label {
        color: var(--ion-color-secondary);
        font-weight: 700;
    }
    &.mobile {
        flex-wrap: wrap;
        padding: 15px 0px !important;
        .count-info:not(:first-child) {
            margin-top: 20px;
        }
        .value {
            font-size: 20px;
            padding: 5px 12px;
        }
        ion-label {
            font-size: 18px;
        }
    }
    &:not(.mobile) {
        padding: 10px 0px;
        ion-label {
            font-size: 22px;
        }
    }
}

.counts-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    .search-counts:first-child {
        margin-right: 80px;
    }
}

.filter-card {
    ion-card-header {
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 20px;
        ion-icon {
            font-size: 24px;
        }
    }
    ion-label {
        color: var(--ion-color-secondary);
        font-weight: 700;
    }
    .warning-icon {
        font-size: 24px;
        margin-right: 10px;
    }
    .filter-items-wrapper {
        display: flex;
        justify-content: space-between;
        .filter-item {
            ion-label {
                color: var(--ion-color-secondary);
            }
            ion-range {
                min-width: 250px;
            }
            ion-button {
                min-width: 150px;
            }
            .input-range {
                display: flex;
                justify-content: space-between;
                align-items: center;
                ion-item {
                    flex: 1;
                    ion-input {
                        margin-bottom: unset;
                        input {
                            font-size: 14px;
                            text-align: right;
                            padding: 8px;
                        }
                        input::placeholder {
                            font-size: 14px;
                        }
                        border: 1px solid var(--ion-input-border-color);
                        border-radius: 10px;
                        &.warning {
                            border-color: var(--ion-color-warning);
                        }
                        &.has-focus,
                        &:hover {
                            border-color: var(--ion-color-primary);
                        }
                    }
                    &::part(native) {
                        padding: unset;
                    }
                }
                .input-with-unit-wrapper {
                    display: flex;
                    ion-input {
                        border-right: none;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                    .measurement-unit {
                        border: 1px solid var(--ion-input-border-color);
                        border-left: unset;
                        background-color: #f2f2f2;
                        font-size: 14px;
                        width: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0px 5px;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                        &.small-font {
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                    &.warning {
                        ion-input {
                            border-color: var(--ion-color-warning);
                        }
                        .measurement-unit {
                            border-color: var(--ion-color-warning);
                        }
                    }
                    &:has(ion-input.has-focus),
                    &:has(ion-input:hover) {
                        ion-input {
                            border-color: var(--ion-color-primary);
                        }
                        .measurement-unit {
                            border-color: var(--ion-color-primary);
                        }
                    }
                }
            }
        }
        .separator {
            margin: 20px 5px !important;
            width: 1px !important;
            height: 50px !important;
        }
        .filter-button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &:not(.mobile) {
        padding: 16px 16px 0px 16px;
        // ion-range,
        // .input-range {
        //     margin-top: 15px;
        // }
    }
    &.mobile {
        .filter-items-wrapper {
            display: block;
            .filter-item {
                margin-bottom: 10px;
                .input-range {
                    ion-item {
                        ion-input {
                            input {
                                text-align: left;
                            }
                        }
                    }
                }
            }
            .separator {
                display: none;
            }
        }
        &:not(.expanded-filters) {
            ion-card-header {
                padding-bottom: 0px;
            }
            .filters-wrapper {
                display: none;
            }
            .filter-items-wrapper {
                display: none;
            }
        }
        // .filters-wrapper {
        //     ion-grid {
        //         padding: unset;
        //         ion-col {
        //             padding: 0px 0px 10px;
        //         }
        //     }
        // }
    }
}

.search-results {
    /* desktop */
    height: 620px;
    &.no-results {
        height: unset;
    }
    display: flex;
    justify-content: center;
    .estates-list {
        overflow-y: overlay;
        &:not(.mobile) {
            width: 420px;
        }
    }
    .market-info-gmap-wrapper {
        flex: 1;
        height: 100%;
        position: relative;
        .toggle-list-btn {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1;
        }
    }

    .toggle-list-btn-mobile {
        width: 90%;
        margin: 25px auto;
    }

    @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
        height: unset;
        .estates-list {
            width: auto;
            .estate-list-item {
                width: inherit;
            }
            &:not(.mobile) {
                width: auto;
            }
        }
    }
}
