.estate-mobile-nav{
    background: white;
    padding: 0px 5px;
    --color: var(--ion-color-secondary);
    --font-weight: 500;
    &.ios-device{
        padding: 0px 5px 15px;
    }
    ion-tab-button {
        --background: white;
        svg {
            width: 30px;
            height: 30px;
            & > g,
            path {
                stroke-width: 1.4 !important;
            }
        }
    }
}
