div.history-item-wrapper {
    position: relative;

    margin: 0 24px;
    padding-bottom: 12px;

    &.history-item-mobile {
        margin: 0 16px 16px 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #86949f57;

        &:last-of-type {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }

    .history-item {
        display: flex;
        position: relative;

        .history-icon {
            min-width: 48px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            text-align: center;
            z-index: 100;
            color: #fff;
            background: #fff;

            > svg {
                margin: 12px;
            }
        }

        .history-icon-mobile {
            border: 1px solid #86949f57;

            min-width: 38px;
            width: 38px;
            height: 38px;

            display: flex;
            align-items: center;
            justify-content: center;

            > svg {
                width: 20px;
                height: 20px;
                display: flex;
                margin: 8px;
            }
        }

        .history-icon-desktop {
            background: #f7f7f7;
        }
    }

    .history-separator {
        width: 25px;
        height: 100%;
        border-right: 2px solid #f7f7f7;
        position: absolute;
        top: 0;
        left: 0;

        &.is-last {
            display: none;
        }
    }

    .history-info-mobile {
        margin: 0 16px;
        width: 100%;
        overflow: hidden;

        .info-title {
            color: #475b6c;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
        }

        .info-message {
            font-weight: 400;
            font-size: 14px;
            color: #475b6c;
        }

        .info-footer {
            font-size: 14px;
            margin-top: 6px;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .info-user,
            .info-date {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .info-user {
                flex: 1;
            }
            .info-date {
                margin-left: 10px;
            }
        }
    }

    .history-info-desktop {
        flex-grow: 1;
        // max-width: 802px;
        margin-left: 20px;
        padding: 8px 24px;

        background: #f7f7f7;
        border-radius: 10px;

        .info-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;

            .info-date {
                display: flex;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: var(--ion-color-medium-text);
            }
        }

        .info-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            color: #475b6c;
        }

        .info-message {
            font-weight: 400;
            font-size: 14px;
            color: #475b6c;
        }

        .info-user {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            margin-top: 4px;
            color: var(--ion-color-medium-text);
        }
    }
}

.history-text-icon {
    line-height: 48px;
    font-size: 20px;
}
