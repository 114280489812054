.ion-page.login-page {
    justify-content: flex-start;

    .page-content {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }

    .powered-by-wrapper {
        position: absolute;
        bottom: 40px;
        width: 100%;
    }
}

.login-header {
    background-color: rgb(0, 106, 201);
    height: 45%;
    width: 100%;
    border-radius: 0 0 20px 20px;
    text-align: center;
    position: relative;
    svg{
        width: 300px;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        top: calc(55% - 100px);
        transform: translateY(-30%);
    }
}

.login-form-wrapper {
    margin-top: -120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    ion-item {
        background-color: white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px 0px;
        padding: 0 10px;
        border-style: solid;
        border-width: 1px;
        border-color: white;
        margin-bottom: 12px;
    }

    ion-label.sc-ion-label-ios-h {
        color: var(--ion-color-medium-shade);
        --padding-top: 2px;
        --padding-bottom: 2px;
        margin-bottom: 0;
        --min-height: 24px;
    }

    ion-input.sc-ion-input-ios-h {
        border: 0;
        input {
            font-size: 14px;
            width: 255px;
            &:-webkit-autofill {
                -webkit-background-clip: text;
                -webkit-text-fill-color: black;
            }
            transform: none !important;
        }
        .cloned-input{
            display: none;
        }
        input::placeholder {
            font-size: 14px;
        }
    }

    .loading-btn-wrapper {
        width: 255px;
        margin-top: 15px;
    }

    .btn-login {
        height: 45px;
    }
}

ion-button {
    --border-radius: 10px;
}
