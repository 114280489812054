.trim-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.estate-card {
    cursor: pointer;
    max-width: 350px;
    margin-bottom: unset;
    .card-header {
        position: relative;
        .estate-img {
            width: 100%;
            min-width: 350px;
            height: 233px;
            object-fit: unset;
        }
    }
    .card-content {
        padding: 10px 20px 10px 20px;
        .card-title {
            font-weight: 500;
            font-size: 16px;
            color: var(--ion-color-secondary);
            margin-bottom: 10px;
            @extend .trim-overflow;
        }
        .card-subtitle {
            font-size: 14px;
            margin-bottom: 8px;
            color: var(--ion-color-medium-text);
            font-weight: 400;
            @extend .trim-overflow;
        }
    }
    .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--ion-color-light-shade);
        margin: 0 16px;
        padding: 4px 0;
        .agency-logo {
            display: flex;
            justify-content: flex-end;
            width: 84px;
            height: 44px;
            img {
                height: 100%;
                max-width: 100%;
                object-fit: contain;
            }
        }
    }
}
