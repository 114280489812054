.document-cards-wrapper {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: space-between;
    ion-card {
        margin-left: unset;
        margin-right: unset;
        width: 100%;
        &.column {
            width: 49%;
            height: fit-content;
        }
    }
}
.document-card {
    ion-card-header {
        display: flex;
        justify-content: space-between;
        ion-icon {
            font-size: 24px;
        }
    }

    &:not(.expanded-docs) {
        ion-card-content {
            display: none;
        }
    }
}
.no-padding {
    padding: 0;
}
@media screen and (max-width: 1100px) {
    .document-cards-wrapper {
        display: block;
        ion-card {
            &.column {
                width: 100%;
            }
        }
    }
}
