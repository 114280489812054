.dashboard-header {
    height: 110px;
    background: linear-gradient(90deg, #3e6fc3 35.33%, rgba(62, 111, 195, 0) 100%);
    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
    .dashboard-text {
        position: absolute;
        color: #fff;
        top: 25px;
        left: 16px;
        line-height: 32px;
        .dashboard-user {
            font-size: 24px;
            font-weight: 700;
        }
    }
    .dashboard-img {
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        object-fit: cover;
        position: relative;
        z-index: -1;
    }
}

.estates-wrapper{
    //whise logo must stay at the bottom
    min-height: calc(100vh - 380px);
    .estates {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;
        justify-content: center;
    }
    &.no-estates-activated{
        display: flex;
        align-items: center;
    }
}