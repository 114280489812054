.powered-by-wrapper {
    text-align: center;
    padding: 30px;
    .powered-label {
        color: #3e6fc3;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
    }
    .powered-logo {
        width: 105px;
    }
}
