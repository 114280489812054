ion-card-header {
    &.mobile {
        .filter-component {
            width: 100%;
            margin: 16px 0;
        }
    }
    &:not(.mobile) {
        justify-content: space-between;
        display: flex;
        .filter-component {
            min-width: 300px;
        }
    }
}

.filter-clear-button {
    position: absolute;
    z-index: 3;
    top: 24px;
    right: 45px;
    font-size: 13px;
    height: fit-content;
    color: var(--placeholder-color);
    opacity: var(--placeholder-opacity);
}

.filter-component {
    background: #ffffff;
    border: 1px solid rgba(134, 148, 159, 0.3);
    border-radius: 10px;
    color: #475b6c;
    padding-right: 15px;
    font-size: 14px;
    height: 40px;

    &.filter-mobile {
        padding-right: 0;
    }
}

.filter-component::part(native) {
    --min-height: 38px;

    .input-wrapper {
        height: 38px;
    }
}

.filter-component::part(detail-icon) {
    width: 12px;
    height: 18px;
}

.filter-placeholder {
    opacity: 0.33;
}
