.styled-scroll,
.estate-preview,
.estates-list {
    overflow: auto;
    &:hover {
        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar,
        &::-webkit-scrollbar-thumb {
            visibility: visible;
        }
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: #f5f5f5;
        border-radius: 10px;
        visibility: hidden;
    }

    &::-webkit-scrollbar {
        height: 7px;
        width: 7px;
        background-color: #f5f5f5;
        visibility: hidden;
    }

    &::-webkit-scrollbar-thumb {
        visibility: hidden;
        border-radius: 10px;
        // background-color: #fff;
        background-color: gray;
        // background-image: -webkit-gradient(
        //     linear,
        //     40% 0%,
        //     75% 84%,
        //     from(rgba(134, 148, 159, 0.25)),
        //     to(rgba(134, 148, 159, 0.25)),
        //     color-stop(0.6, rgba(134, 148, 159, 0.25))
        // );
    }

    scrollbar-color: rgba(134, 148, 159, 0.25) #f5f5f5;
    scrollbar-width: thin;
}
