.trim-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
ion-card,
ion-row {
    &.page-section {
        margin-left: 10%;
        margin-right: 10%;
        .no-offer-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            padding-top: unset;
        }
        &.mobile {
            margin-left: 16px;
            margin-right: 16px;
            ion-card-header {
                padding: 16px;
            }
            ion-card-content:not(.no-padding) {
                padding: 0px 16px 16px;
            }
            .no-offer-content {
                padding: 16px;
            }
        }
    }
}
ion-card-header > ion-card-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: var(--ion-color-secondary);
    &::first-letter {
        text-transform: uppercase;
    }
    @media screen and (max-width: 768px) {
        /* mobile device */
        font-size: 18px;
        line-height: 26px;
        margin-left: unset;
    }
    @extend .trim-overflow;
}

.card-content-ios {
    font-size: 14px;
}
