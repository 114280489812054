.eye-icon {
    cursor: pointer;
    width: 20px; 
    height: 20px;
    position: absolute;
    right: 0;
    z-index: 100;
}

.password-input {
    input {
        --padding-end: 20px;
    }
}