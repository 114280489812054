.swiper {
    width: 100%;
    height: 100%;
    user-select: none;
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            display: block;
            width: 100%;
            height: 360px;
            object-fit: cover;
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        color: var(--ion-color-secondary);
        background: #fff;
        border-radius: 50%;
        padding: 8px;
        width: 40px;
        height: 40px;
        &::after {
            font-size: 18px;
        }
        &.swiper-button-disabled {
            pointer-events: all;
        }
    }
    .swiper-pagination {
        padding: 0px 5px;
        .swiper-pagination-bullet {
            background: #fff;
            opacity: 0.6;
            border: 1px solid gray;
            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }
}

.swipper-fullscreen {
    --width: 100vw;
    --border-radius: 0px;
    &:not(.mobile){
        --height: 100%;
    }
    .close-btn {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        border-radius: 50%;
        display: flex;
        padding: 7px;
        color: var(--ion-color-secondary);
        background-color: #fff;
        font-size: 28px;
        z-index: 1;
    }
    .swiper {
        .swiper-slide {
            img {
                display: block;
                object-fit: unset;
            }
            @media (orientation: landscape) {
                img{
                    height: 100%;
                    width: unset;
                }
            }
            @media (orientation: portrait) {
                img{
                    height: unset;
                    width: 100%;
                }
            }
        }
    }
    &.ios-device{
        .close-btn {
            right: 20px;
            top: 20px;
        }
    }
}
