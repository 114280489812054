.profile-buttons {
    border: 1px solid var(--ion-input-border-color);
    border-radius: 10px;
    display: inline-flex;
    margin-top: 15px;
    .profile-button {
        --padding: 11px 16px;
        height: 46px;
        --border-radius: 0px;
        margin: unset;
        &:first-child{
            --border-radius: 10px 0px 0px 10px;
        }
        &:last-child{
            --border-radius: 0px 10px 10px 0px;
        }
        .button-label {
            margin-left: 8px;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: var(--ion-color-secondary);
        }
        &.selected,&:hover {
            --background: #3e6fc3;
            svg > g,
            path {
                stroke: #fff;
            }
            line {
                stroke: #fff;
            }
            .button-label{
                color: white;
            }
        }
    }
}
