.profile-page {
    //whise logo must stay at the bottom
    min-height: calc(100vh - 180px);
    padding: 0 10%;
    ion-label.label-stacked.sc-ion-label-ios-h {
        color: var(--ion-color-secondary);
        font-weight: 500;
    }

    ion-item::part(native) {
        padding-inline-start: 0;
        padding-inline-end: 0;
    }

    ion-grid,
    ion-col {
        padding-left: 0;
        padding-right: 0;
        padding: unset;
    }
    ion-card {
        margin-bottom: unset;
        .card-content-ios {
            padding: 0 24px 24px 24px;
        }
    }

    ion-card-header {
        padding: 24px 24px 16px 24px;
        ion-card-title {
            margin-left: 0;
        }
    }

    ion-input input.native-input {
        &::placeholder {
            font-size: 14px;
            color: var(--ion-color-secondary);
        }
        padding: 8px 10px;
    }

    .user-info {
        margin: 24px 16px 0px;
        ion-card-content {
            padding-top: 24px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            color: var(--ion-color-secondary);
            .avatar-wrapper {
                flex: 0 0 64px;
                margin-right: 15px;
                position: relative;
                .image-wrapper {
                    border: 1px solid var(--ion-input-border-color);
                    border-radius: 50%;
                    overflow: hidden;
                    width: 56px;
                    height: 56px;
                    ion-img {
                        height: inherit;
                        width: inherit;
                        background-color: #222d3a;
                    }
                }
                .edit-icon {
                    position: absolute;
                    top: 0;
                    cursor: pointer;
                    border-radius: 50%;
                    text-align: center;
                    right: 0;
                    background-color: #ffffff;
                    box-shadow: 1px 1px 2px 1px #b9b9b9;
                    width: 28px;
                    height: 28px;
                    padding-top: 4px;
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
    .apply-button-wrapper {
        margin-top: 20px;
        margin-bottom: unset;
        min-width: 280px;
        height: 2.8em;
    }
    .filter-component {
        padding: 0px 15px;
        width: 100%;
    }
    ion-item {
        --background-hover: transparent;
        --background-focused: transparent;
        --background-activated: transparent;
    }
}

@media screen and (max-width: 992px) {
    .profile-page {
        .apply-button-wrapper {
            display: flex;
            flex: 1;
            justify-content: center;
            min-width: 90%;
        }
        ion-input input.native-input {
            padding: 8px 0;
        }
    }
}
@media screen and (max-width: 768px) {
    .profile-page {
        padding: 0;
        ion-card {
            .card-content-ios {
                padding: 0 16px 16px 16px;
            }
            &.user-info ion-card-content {
                padding-top: 16px;
            }
        }

        ion-card-header {
            padding: 16px 16px 8px 16px;
            ion-card-title {
                margin-left: 0;
            }
        }
    }
}
