.my-property {
    .estate-name {
        font-weight: 700;
        font-size: 22px;
        color: #475b6c;
        &.mobile {
            font-size: 18px;
        }
    }

    .custom-card {
        min-height: 160px;
    }

    .property-card {
        margin-left: 0;
    }

    .mobile {
        .pictures-card {
            padding-top: 16px;
            margin-bottom: 0;
        }
    }

    .owners-card {
        margin-right: 0;
        &:not(.mobile) {
            .styled-scroll {
                max-height: 82px;
            }
        }
    }

    .link-button {
        margin: 0;
        font-size: 16px;
        font-weight: 700;
        height: 44px;

        &::part(native) {
            padding: 0 20px 0 0;
        }

        a {
            text-decoration: none;
            line-height: 44px;
            padding-left: 10px;
        }
    }

    .divider {
        border: 1px solid #86949f57;
        margin: 24px 0;
    }

    .description {
        position: relative;
    }

    .description-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #475b6c;
        margin-bottom: 16px;
    }

    .description-content {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #86949f;

        &.collapsed {
            max-height: 165px;
            overflow-y: hidden;
        }

        &.expanded {
            margin-bottom: 10px;
        }
    }

    .description-gradient {
        position: absolute;
        bottom: 23px;
        width: 100%;
        height: 60px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 64.71%);
    }

    .read-more {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: var(--ion-color-primary);
        span {
            cursor: pointer;
        }
    }

    .subdetails {
        margin: 32px 0 16px 0;

        .subdetail-list {
            .subdetail-list-item {
                &:nth-child(even) {
                    background-color: #ffffff;
                }

                &:nth-child(odd) {
                    background-color: #f7f7f7;
                }

                padding: 16px;
                border-radius: 10px;

                &:hover {
                    background-color: #aaaaaa;
                }
            }
        }
    }

    .features-desktop {
        .features-item {
            margin-top: 16px;
            &:first-of-type {
                margin-top: 8px;
            }

            border-bottom: 1px solid #86949f57;
            &:last-of-type {
                border-bottom: 0;
            }

            .features-label {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #86949f;
            }

            .features-content {
                height: 32px;
                margin: 8px 0;
                display: flex;
                .features-icon {
                    margin-right: 8px;
                    svg {
                        width: 32px;
                        height: 32px;
                    }
                }
                .features-value {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 32px;
                    color: #475b6c;
                    height: 32px;
                }
            }
        }
    }

    .features-mobile {
        display: flex;
        margin-top: 8px;

        .features-item {
            .features-content {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 10px;
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }

    .feature-card {
        margin-right: 0;
    }
}

.pictures-card {
    margin-left: 0;
    margin-right: 0;

    img {
        border-radius: 5px;
    }
}

.page-section {
    margin-right: 16px;
    margin-left: 16px;
}

@media (pointer: fine), (pointer: none) {
    /* desktop */
    .page-section {
        margin-left: 10%;
        margin-right: 10%;
    }
}

// @media screen and (max-width: 1199px) {
//     .owners-card {
//         margin-top: 0;
//         margin-left: 0;
//     }

//     .property-card {
//         margin-left: 0;
//     }

//     .owners-card {
//         margin-right: 0;
//         &:not(.mobile) {
//             ion-card-content {
//                 max-height: 80px;
//                 margin-bottom: 20px;
//             }
//         }
//     }

//     .pictures-card {
//         margin-left: 0;
//         margin-right: 0;
//         .swiper {
//             height: 330px;
//         }
//     }

.feature-card {
    margin-top: 0;
    margin-left: 0;
}

.property-card {
    margin-top: 0;
    margin-right: 0;
}
.no-margin-on-small-screen {
    margin-bottom: 0px;
}

@media screen and (min-width: 1200px) {
    .custom-card {
        margin-bottom: 0px;
    }
    .feature-card {
        margin-top: 24px;
        margin-left: 16px;
    }

    .property-card {
        margin-top: 24px;
        margin-right: 16px;
    }
}

.space-between-align-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-subtitle {
    font-size: 16px;
    color: var(--ion-color-primary);
    font-weight: 500;
}

.margin-bottom {
    margin-bottom: 10px;
}

.estate-info {
    color: #86949f;
    font-weight: 400;
    font-size: 14px;
    margin-right: 10px;

    .address {
        display: flex;
        svg {
            min-width: 20px;
        }
        .address-text {
            margin-left: 3px;
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.price {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    flex-shrink: 0;
    color: var(--ion-color-primary);
    &.mobile {
        font-size: 20px;
    }
}
.status-label {
    align-self: center;
    height: 21px;
    min-width: max-content;
}

.estate-image {
    margin-bottom: 15px;
}

ion-img::part(image) {
    border-radius: 5px;
}
// }
