.forgot-password-wrapper{
    .go-to-login {
        --color: var(--ion-color-primary);
    }
    .forgot-password-title{
        color: #fff;
        font-weight: 500;
        font-size: 20px;
        position: absolute;
        top: calc(55% - 30px);
        left: 0;
        right: 0;
    }
    .message-box{
        background: #fff;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 1px 3px 0px #dbdbdb;
        width: 350px;
        text-align: center;
        max-width: 85vw;
        ion-icon{
            width: 60px;
            height: 60px;
        }
        .text{
            color: var(--ion-color-secondary);
            font-size: 16px;
            h5{
                font-size: 16px;
            }
        }
        &.process-finished{
            margin-top: 20px;
            margin-top: 10px;
            font-weight: 500;
            ion-icon{
                color: green;  
                margin-bottom: 12px;
            }
            .loading-btn-wrapper{
                margin: auto;
                margin-top: 20px;
            }
        }
        &.mail-sent{
            margin-top: 10px;
            ion-icon{
                color: var(--ion-color-primary);  
            }
        }
    }
    .btn-action {
        height: 45px;
    }
}