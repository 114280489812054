.trim-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.navbar {
    padding: 16px 16px 0 16px;
}
.nav-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .back-nav-button {
        display: flex;
        align-items: center;
    }
    .back-button,
    .picture-button-mobile {
        --background: white;
        --background-hover: white;
        --background-activated: white;
        --background-focused: white;
        --color: steelblue;
        --border-radius: 25px;
        height: 50px;
        width: 50px;
        flex-shrink: 0;
    }
    .picture-button-desktop{
        --background: white;
        --color: var(--ion-color-secondary);
        font-weight: 500;
        opacity: unset;
    }
    .button-label {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #ffffff;
        margin-left: 16px;
    }
}

.loading-btn-wrapper {
    position: relative;
    .btn-action {
        font-weight: 500;
        font-size: 14px;
    }
    .wait-icon {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    margin-bottom: 30px;
}

.forgot-password{
    font-weight: 500;
    color: var(--ion-color-primary);
    cursor: pointer;
}