.image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: auto!important;
    max-height: unset;
    overflow-y: hidden;
    opacity: 0.3;
    z-index: 100;

    &.mobile {
        height: 100%!important;
        width: auto!important;
        max-width: unset;
        overflow-x: hidden;
    }
}

.image {
    z-index: 101;
}