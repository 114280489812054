.no-results{
    width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        text-align: center;
        .no-results-icon{
            margin-bottom: 20px;
            ion-icon{
                font-size: 60px;
                color: var(--ion-color-primary);
            }
            svg{
                width: 60px;
                height: 60px;
                display: block;
                margin: auto;
                &  > g,
                path {
                    stroke: var(--ion-color-primary);
                }
                line {
                    stroke: var(--ion-color-primary);
                }
            }
        }

        .no-results-title{
            font-size: 18px;
            color: var(--ion-color-secondary);
            margin-bottom: 9px;
            font-weight: 600;
        }
        .no-results-info{
            font-size: 16px;
            color: var(--ion-color-secondary);
            width: 80%;
            margin: auto;
        }
}