.general-layout {
    ion-content {
        --background: #f3f3f3;
    }
    ion-toolbar {
        --background: white;
        .logo{ 
            svg {
                margin: 5px 0;
                height: 42px;
                display: block;
                width: 180px;
            }
            &.mobile svg{
                margin: 5px 0 5px 12px;
            }
        }
     
        .user-name {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin-right: 16px;

            color: var(--ion-color-secondary);
        }
        .user-menu-button {
            color: var(--ion-color-medium-text);
        }
    }
    .image-wrapper {
        border-radius: 50%;
        overflow: hidden;
        width: 36px;
        height: 36px;
        ion-img,
        svg {
            height: inherit;
            width: inherit;
        }
    }
    .logo-nav-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .profile-buttons{
            margin-top: unset;
            border: unset;
           .profile-button{
                --border-radius: 0px;
           }
        }
        @media screen and (max-width: 1450px){
            .button-label{
                display: none;
            }
        }
    }
}
