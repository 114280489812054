@import 'react-tooltip/dist/react-tooltip.css';
@import './commonStyles.scss';
@import './ionInput.scss';
@import './ionTabBar.scss';
@import './ionRange.scss';
@import './ionCard.scss';
@import './ionContent.scss';
@import './ionRouterOutlet.scss';
@media (pointer: coarse) {
    /* mobile device */
}

@media (pointer: fine), (pointer: none) {
    /* desktop */
    @import './styledScroll.scss';
}

@media (pointer: fine) and (any-pointer: coarse) {
    /* touch desktop */
}

@media screen and (max-width: 320px), screen and (max-height: 430px) {
    div.ion-page {
        overflow: auto;
        ion-header {
            min-width: 320px;
        }
        ion-content {
            min-width: 320px;
        }
        ion-footer {
            min-width: 320px;
        }
    }
}
