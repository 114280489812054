ion-range:not(.ion-color-warning)::part(tick) {
    background: #a2d2ff;
}

ion-range:not(.ion-color-warning)::part(tick-active) {
    background: var(--ion-color-primary);
}

ion-range {
    &:not(.ion-color-warning)::part(pin) {
        color: var(--ion-color-primary);
    }
    &::part(pin) {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        font-weight: 700;

        transform: scale(1.01);

        top: 45px;
        white-space: pre;

        min-width: 28px;
        height: 28px;
        transition: transform 120ms ease, background 120ms ease;
    }
}

ion-range::part(pin)::before {
    content: none;
}

ion-range:not(.ion-color-warning)::part(knob) {
    background: var(--ion-color-primary);
}

ion-range:not(.ion-color-warning)::part(bar) {
    background: #a2d2ff;
}

ion-range:not(.ion-color-warning)::part(bar-active) {
    background: var(--ion-color-primary);
}

.range-has-pin {
    padding: 0px 15px 20px;
}
