.crop-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: #404040;
}
.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 70px);
}

.controls {
    background-color: white;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 70px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    ion-icon{
        margin-left: 10px;
        font-size: 25px;
        color: var(--ion-color-primary);
        width: 33px;
        height: 33px;
    }
    .range-input{
        flex: 1;
        margin-top: 10px;
        .range-input-label{
            margin-bottom: -12px;
            font-weight: 500;
        }
    }
}
.crop-wrapper.ios{
    .crop-container{
        height: calc(100% - 90px);
    }
    .controls{
        height: 90px;
        padding-bottom: 25px;
    }
}